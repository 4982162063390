// index.js

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { Spinner } from 'reactstrap';

import Loading from 'components/Loading/index';
import 'bootstrap/dist/css/bootstrap.css';
import 'assets/scss/now-ui-dashboard.scss?v1.4.0';
import { GoogleOAuthProvider } from 'react-oauth-google';
import AdminLayout from 'layouts/Admin.js';
import LoginPage from 'views/Login/LoginPage';
import { AuthProvider, getAuthToken } from './authContext/index';
import PrivateRoute from 'components/PrivateRoute';

const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

const hist = createBrowserHistory();
const authToken = getAuthToken();
ReactDOM.render(
  <AuthProvider initialAuthState={{ user: null, logged: Boolean(authToken) }}>
    <GoogleOAuthProvider clientId={clientId}>
      <Router history={hist}>
        <Suspense
          fallback={
            <>
              <Loading>
                <Spinner color="primary" className="spinner" size="lg" />
                <h4>Loading Dashboard...</h4>
              </Loading>
            </>
          }
        >
          <Switch>
            <PrivateRoute path="/admin" component={AdminLayout} />
            <Route path="/login" render={() => <LoginPage />} />
            <Redirect to="/login" />
          </Switch>
        </Suspense>
        </Router>
    </GoogleOAuthProvider>
  </AuthProvider>,
  document.getElementById('root'),
);
