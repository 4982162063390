import React, { useContext, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import saltuIcon from "./Assets/saltuIcon.svg";
import { GoogleLogin } from "react-oauth-google";
import loginLowerWaveBig from "./Assets/loginLowerWaveBig.svg";
import loginLowerWaveSmall from "./Assets/loginLowerWaveSmall.svg";
import loginUpperWaveBig from "./Assets/loginUpperWaveBig.svg";
import loginUpperWaveSmall from "./Assets/loginUpperWaveSmall.svg";
import facebook from "./Assets/facebookIcon.svg";
import instagram from "./Assets/instagramIcon.svg";
import linkedin from "./Assets/linkedinIcon.svg";
import { AuthContext } from "../../authContext/index";
import { useHistory } from 'react-router-dom';
import axios from "axios";
import dotenv from 'dotenv';

export default function LoginPage() {
  const { setAuthState, authState } = useContext(AuthContext);
  const history = useHistory();

  const handleLogin = async (e) => {
    try {
      const tokenPayload = e.credential.split('.')[1];

      const decodedToken = JSON.parse(atob(tokenPayload));

      // Access user information
      const userData = {
        email: decodedToken.email,
        name: decodedToken.name,
        firstName: decodedToken.given_name,
        picture: decodedToken.picture,
      };

      const existUserResponse = await fetch(`${process.env.REACT_APP_API_URL}/verificar-usuario/${userData.email}`, { method: 'GET' });
      const existUser = await existUserResponse.json();
      console.log(existUser);

      if (!existUser.error) {
        setAuthState({ user: userData });
        history.push('/admin/dashboard/dinopixel');
      } else {
        const createdUserResponse = await fetch(`${process.env.REACT_APP_API_URL}/inserir-usuario/${userData.email}/${userData.name}`, { method: 'POST' });
        const createdUser = await createdUserResponse.json();
        console.log(createdUser);

        if (createdUser.message === 'User created successfully') {
          setAuthState({ user: userData });
          history.push('/admin/dashboard/dinopixel');
        } else {
          console.log("Nunca vai cair aqui");
        }
      }
    } catch (error) {
      console.error('Erro durante o login:', error);
    }
  };

  useEffect(() => {
    // This will log the updated authState when it changes
    console.log("authstate: ", authState);
  }, [authState]);

  return (
    <LoginDiv>
      <LeftPage>
        <Logo>
          <SaltuIcon src={saltuIcon} />
          <Name>Plataforma Arkade</Name>
        </Logo>
        <LeftFooter>
          Copyright 2023 Arkade Dashboard. All Rights Reserved.
        </LeftFooter>
      </LeftPage>
      <RightPage>
        <LoginContainer>
          <WellcomeMessage>Bem vindo a Plataforma Arkade</WellcomeMessage>
          <GoogleContainer>
            <LoginMessage>Faça parte da plataforma hoje</LoginMessage>
            <GoogleLogin onSuccess={handleLogin} />
            <BlueBar />
          </GoogleContainer>
        </LoginContainer>
        <RightFooter>
          <a href="https://instagram.com/ludus.lab?igshid=NzZlODBkYWE4Ng==">
            <Instagram src={instagram} />
          </a>
          <a href="https://www.facebook.com/uealuduslab?mibextid=qWsEUC ">
            <Facebook src={facebook} />
          </a>
          <a href="https://www.linkedin.com/company/ludus-lab/">
            <LinkedIn src={linkedin} />
          </a>
        </RightFooter>
      </RightPage>
    </LoginDiv>
  );
}

const LogoAnimation = keyframes`
    from{
        opacity: 0%;

    }
    to{
        opacity: 100%;

    }
`
/*
const backgroundAnimation = keyframes`
  from {
    background-size: 100%;
  }
  to {
    background-size: 60%;
  }
`;
*/
const backgroundAnimation = keyframes`
  0% {
    background-size: 100%, 100%, 100%, 100%;
  }
  10% {
    background-size: 93%, 96%, 93%, 96%;
  }
  20% {
    background-size: 86%, 92%, 86%, 92%;
  }
  30% {
    background-size: 79%, 88%, 79%, 88%;
  }
  40% {
    background-size: 72%, 84%, 72%, 84%;
  }
  50% {
    background-size: 79%, 88%, 79%, 88%;
  }
  60% {
    background-size: 86%, 92%, 86%, 92%;
  }
  70% {
    background-size: 93%, 96%, 93%, 96%;
  }
  100% {
    background-size: 100%, 100%, 100%, 100%;
  }
`;
/*
const LoginDiv = styled.div`
  background:
    url(${upBlueDetail}) top right no-repeat,
    url(${downBlueDetail}) top left no-repeat;

  background-repeat: no-repeat;
  background-size: 70%; 
  background-position-y:
   -1px,  
   150px;
  display: flex;
  align-items: center;
  animation: ${backgroundAnimation} 1s ease-in-out forwards; 
`;
*/

const LoginDiv = styled.div`
  background:

    url(${loginUpperWaveBig}) top right no-repeat,
    url(${loginUpperWaveSmall}) top right no-repeat,
    url(${loginLowerWaveBig}) top left no-repeat,
    url(${loginLowerWaveSmall}) top left no-repeat;
  background-repeat: no-repeat;
  background-size: 70%; 
  background-position-y:
   -300px, -300px, 
   150px, 150px;
  display: flex;
  align-items: center;
  //animation: ${backgroundAnimation} 1s ease-in-out forwards; 
  animation: ${backgroundAnimation} 50s linear infinite; /* Ajuste a duração total conforme necessário */
  animation-timing-function: linear;
`;


const LeftPage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 50vw;
`;

const RightPage = styled.div`
    display: flex;
    height: 100vh;
    width: 50vw;
    align-items: center;
    justify-content: center;
`


const Logo = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 50px;
    animation: ${LogoAnimation} 2.0s ease-in-out forwards; 

`
const Name = styled.div`
    font: normal normal normal 34px 'Work Sans', sans-serif;
    margin-left: 30px;
    max-width: 30px;
    color: #2CA8FF;

`
const SaltuIcon = styled.img`
    width: 55%;
`

const LeftFooter = styled.div`
    position: absolute;
    margin-bottom: -45%;
    margin-left: -15%;
    font-size: 12px;
`

const RightFooter = styled.div`
    position: absolute;
    margin-bottom: -45%;
    margin-left: 15%;
`


const LoginContainer = styled.div`
    
    display: flex;
    position: absolute;
    flex-direction: column;
    top: 0px;
    left: 780px;
    width: 480px;
    height: 600px;
    box-shadow: 0px 6px 10px #00000029;
    background-color: white;
    border-radius: 0px 0px 10px 10px;
    padding: 20px;
    animation: ${LogoAnimation} 1.0s ease-in-out forwards; 


`
const WellcomeMessage = styled.div`
    margin-top: 60px;
    font: normal normal normal 43px 'Work Sans', sans-serif;
    color: #5FA4DC;
    max-width: 380px;
` 

const GoogleContainer = styled.div`

    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 150px;

` 

const LoginMessage = styled.div`
    font: normal normal normal 14px 'Work Sans', sans-serif;
    color: black;
    max-width: 380px;
    margin-bottom: 15px;
    `

const BlueBar = styled.div`
    border: 2px solid #2CA8FF;
    border-radius: 1.5px;
    width: 200px;
    margin-top:15px;
`

const Instagram = styled.img`
`

const Facebook = styled.img`
margin:10px;
`

const LinkedIn = styled.img``

