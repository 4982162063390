/*!

=========================================================
* Now UI Dashboard React - v1.4.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/now-ui-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import { Link } from 'react-router-dom';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Container,
  Button
} from 'reactstrap';

import routes from 'routes.js';
import { ButtonJson } from 'components/Button/ButtonJson';
import { ButtonCsv } from 'components/Button/ButtonCsv';

class Header extends React.Component {
  state = {
    isOpen: false,
    dropdownOpen: false,
    color: 'transparent',
  };
  sidebarToggle = React.createRef();

  downloadPdf() {
    fetch(`${process.env.REACT_APP_API_FRONT}/REPORT-PlataformaArkade.pdf`).then(res => {
      res.blob().then(blob => {
        const reportURL = window.URL.createObjectURL(blob)
        console.log(reportURL)

        let alink = document.createElement("a")

        alink.href = reportURL
        alink.download = "REPORT-PlataformaArkade.pdf"
        alink.click()
      })
    })
  }

  toggle = () => {
    if (this.state.isOpen) {
      this.setState({
        color: 'transparent',
      });
    } else {
      this.setState({
        color: 'white',
      });
    }
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };
  dropdownToggle = (e) => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  };
  getBrand = () => {
    var name;
    routes.map((prop, key) => {
      if (prop.collapse) {
        prop.views.map((prop_data) => {
          if (prop_data.path === this.props.location.pathname) {
            name = prop_data.name;
          }
          return null;
        });
      } else {
        if (prop.redirect) {
          if (prop.path === this.props.location.pathname) {
            name = prop.name;
          }
        }
      }
      return null;
    });
    return name;
  };
  openSidebar = () => {
    document.documentElement.classList.toggle('nav-open');
    this.sidebarToggle.current.classList.toggle('toggled');
  };
  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  updateColor = () => {
    if (window.innerWidth < 993 && this.state.isOpen) {
      this.setState({
        color: 'white',
      });
    } else {
      this.setState({
        color: 'transparent',
      });
    }
  };
  componentDidMount() {
    window.addEventListener('resize', this.updateColor.bind(this));
  }
  componentDidUpdate(e) {
    if (
      window.innerWidth < 993 &&
      e.history.location.pathname !== e.location.pathname &&
      document.documentElement.className.indexOf('nav-open') !== -1
    ) {
      document.documentElement.classList.toggle('nav-open');
      this.sidebarToggle.current.classList.toggle('toggled');
    }
  }
  render() {
    const path = `${process.env.REACT_APP_API_URL}/exportData/all`
    return (
      // add or remove classes depending if we are on full-screen-maps page or not
      <Navbar
        color={
          this.props.location.pathname.indexOf('full-screen-maps') !== -1
            ? 'white'
            : this.state.color
        }
        expand="lg"
        className={
          this.props.location.pathname.indexOf('full-screen-maps') !== -1
            ? 'navbar-absolute fixed-top'
            : 'navbar-absolute fixed-top ' +
              (this.state.color === 'transparent' ? 'navbar-transparent ' : '')
        }
      >
        <Container fluid>
          <div className="navbar-wrapper">
            <div className="navbar-toggle">
              <button
                type="button"
                ref={this.sidebarToggle}
                className="navbar-toggler"
                onClick={() => this.openSidebar()}
              >
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </button>
            </div>

            <ButtonJson data="monthusers" />
            <ButtonCsv data="monthusers"/>
            <Button color="info" size="sm"><a href={path} download className='download'>All Data</a></Button>
            <Button color='info' size='sm'><a onClick={this.downloadPdf} className='download'>Report PDF</a></Button>            

            <NavbarBrand href="/">{this.getBrand()}</NavbarBrand>
          </div>
          <Collapse
            isOpen={this.state.isOpen}
            navbar
            className="justify-content-end"
          >
            {/*             <form>
              <InputGroup className="no-border">
                <Input placeholder="Search..." />
                <InputGroupAddon addonType="append">
                  <InputGroupText>
                    <i className="now-ui-icons ui-1_zoom-bold" />
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </form> */}
            <Nav navbar>
              {/*               <NavItem>
                <Link to="#pablo" className="nav-link">
                  <i className="now-ui-icons media-2_sound-wave" />
                  <p>
                    <span className="d-lg-none d-md-block">Stats</span>
                  </p>
                </Link>
              </NavItem> */}
              {/* <Dropdown
                nav
                isOpen={this.state.dropdownOpen}
                toggle={(e) => this.dropdownToggle(e)}
              >
                <DropdownToggle caret nav>
                  <i className="now-ui-icons users_single-02" />
                  <p>
                    <span className="d-lg-none d-md-block">Perfil</span>
                  </p>
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem tag={Link} to="/admin/user-page">
                    Meu Perfil
                  </DropdownItem>
                  <DropdownItem tag={Link} to="/admin/user-page">
                    Minha Conta
                  </DropdownItem>
                  <DropdownItem tag={Link} to="/admin/user-page">
                    Logout
                  </DropdownItem>
                  <DropdownItem href={path}>
                    All Data
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>  */}
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    );
  }
}

export default Header;
