/*!

=========================================================
* Now UI Dashboard React - v1.4.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/now-ui-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { lazy } from 'react';

const DashboardBaziyo = lazy(() => import('./views/Dashboard1'));
const DashboardDinoPixel = lazy(() => import('./views/Dashboard3'));
const DashboardCatsInGravity = lazy(() => import('./views/Dashboard4'));
const DashboardKapote = lazy(() => import('./views/Dashboard5'));
const DashboardKaa = lazy(() => import('./views/Dashboard6'));
const DashboardTemexe = lazy(() => import('./views/Dashboard7'));
const UserPage = lazy(() => import('./views/UserPage'));
const HeatMapDino = lazy(() => import('./views/ExtendedHeatMap'))
var dashRoutes = [
  {
    id: 1,
    path: '/dashboard/baziyo',
    name: 'Dashboard Baziyo',
    icon: 'design_app',
    component: DashboardBaziyo,
    layout: '/admin',
  },
  
  {
    id: 3,
    path: '/dashboard/dinopixel',
    name: 'Dashboard DinoPixel',
    icon: 'design_app',
    component: DashboardDinoPixel,
    layout: '/admin',
  },
  {
    id: 4,
    path: '/dashboard/cats_in_gravity',
    name: 'Dashboard Cats In Gravity',
    icon: 'design_app',
    component: DashboardCatsInGravity,
    layout: '/admin',
  },
  {
    id: 5,
    path: '/dashboard/kapote',
    name: 'Dashboard Kapote',
    icon: 'design_app',
    component: DashboardKapote,
    layout: '/admin',
  },
  {
    id: 6,
    path: '/dashboard/kaa',
    name: 'Dashboard Kaa',
    icon: 'design_app',
    component: DashboardKaa,
    layout: '/admin',
  },
  {
    id: 7,
    path: '/dashboard/tmexe',
    name: 'Dashboard TeMexe',
    icon: 'design_app',
    component: DashboardTemexe,
    layout: '/admin',
  },
  {
    id: 8,
    path: '/dashboard/heatmap',
    name: 'HeatMap',
    component: HeatMapDino,
    layout: "/admin"
  }
  // {
  //   path: '/user-page',
  //   name: 'User Profile',
  //   icon: 'users_single-02',
  //   component: UserPage,
  //   layout: '/admin',
  // },
];
export default dashRoutes;
